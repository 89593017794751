import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import LoadScript from "vue-plugin-load-script";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "v-tooltip/dist/v-tooltip.css";
import "./assets/css/tailwind.css";
import SmartTable from "vuejs-smart-table";
import ApiService from "@/common/api.service";
import VTooltip from "v-tooltip";
import vue3GoogleLogin from "vue3-google-login";
import VueMobileDetection from "vue-mobile-detection";

const app = createApp(App);

let CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;

ApiService.init();

// Function to create or update the canonical tag
const updateCanonicalTag = (url) => {
  let link = document.querySelector("link[rel='canonical']");
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "canonical");
    document.head.appendChild(link);
  }
  link.setAttribute("href", url);
};

// Ensure canonical tag updates on initial load
router.isReady().then(() => {
  const initialUrl = `${window.location.origin}${router.currentRoute.value.fullPath}`;
  updateCanonicalTag(initialUrl);
});

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch("addPageLoading");
  if (to.meta.requiresAuth) {
    Promise.all([store.dispatch("checkAuth")]).then(() => next()); // Note: next() inside the .then()
  } else {
    next();
  }
});

router.afterEach((to) => {
  const canonicalUrl = `${window.location.origin}${to.fullPath}`;
  updateCanonicalTag(canonicalUrl);
  setTimeout(() => store.dispatch("pageLoadingComplete"), 100);
});

app.use(VTooltip);

app.config.globalProperties.$filters = {
  arrayCommaString(array) {
    if (Array.isArray(array)) {
      if (array.length == 0) return "";
      return array.join(", ");
    }
  },
};

const options = {
  // You can set your default options here
  position: POSITION.TOP_CENTER,
};

app.use(Toast, options);
app.use(store);
app.use(LoadScript);
app.use(SmartTable);
app.use(router);
app.use(VCalendar);
app.use(vue3GoogleLogin, {
  clientId: CLIENT_ID,
});
app.use(VueMobileDetection);
app.mount("#app");
